import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Modal controller connected")
    this.handleKeyPress = this.handleKeyPress.bind(this)
    document.addEventListener('keydown', this.handleKeyPress)
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  handleKeyPress(event) {
    if (event.key === "Escape") {
      this.close()
    }
  }

  open(event) {
    event.preventDefault()
    console.log("Opening modal", event.currentTarget.dataset.modalId)
    const modalId = event.currentTarget.dataset.modalId
    const modal = document.getElementById(modalId)
    console.log("Found modal:", modal)
    if (modal) {
      modal.classList.remove('hidden')
    }
  }

  close() {
    this.element.classList.add('hidden')
  }
}